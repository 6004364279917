import React from "react";
import ImageGallery from "react-image-gallery";

const SwiperWrapper = ({ images }) => {
  return (
    <div className='img-container'>
      <ImageGallery showPlayButton={false} items={images} />
    </div>
  );
};

export default SwiperWrapper;
