import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ViverePraiano = () => {
	const {t} = useTranslation();

	return (
		<div>
			<StyledParallaxImageContainer id="header" >
				<StyledVistaContainer>
					<h1>{t("viverePraiano.title")}</h1>
					<StyledConchigliaImage
						src='/imgs/corallo.svg'
						alt='nautilus'
						width='110px'
					/>
				</StyledVistaContainer>
			</StyledParallaxImageContainer>
			<div className='container'>
				<StyledCardContainer>
					<StyledCard>
						<h1>{t("viverePraiano.spiagge.title")}</h1>
						<StyledCardBody className='cardBody'>
							<h3>{t("viverePraiano.spiagge.gavitella.title")}</h3>
							<p>{t("viverePraiano.spiagge.gavitella.body")}</p>
						</StyledCardBody>
						<StyledCardBody className='cardBody'>
							<h3>{t("viverePraiano.spiagge.praia.title")}</h3>
							<p>{t("viverePraiano.spiagge.praia.body")}</p>
						</StyledCardBody>
						<StyledCardBody className='cardBody'>
							<h3>{t("viverePraiano.spiagge.tramonto.title")}</h3>
							<p>{t("viverePraiano.spiagge.tramonto.body")}</p>
						</StyledCardBody>
					</StyledCard>
					<StyledCard>
						<h1>{t("viverePraiano.shopping.title")}</h1>
						<StyledCardBody className='cardBody'>
							<h3>{t("viverePraiano.shopping.bacheca.title")}</h3>
							<p>{t("viverePraiano.shopping.bacheca.body")}</p>
						</StyledCardBody>
						<StyledCardBody className='cardBody'>
							<h3>{t("viverePraiano.shopping.damario.title")}</h3>
							<p>{t("viverePraiano.shopping.damario.body")}</p>
						</StyledCardBody>
						<StyledCardBody className='cardBody'>
							<h3>{t("viverePraiano.shopping.sandulli.title")}</h3>
							<p>{t("viverePraiano.shopping.sandulli.body")}</p>
						</StyledCardBody>
						<StyledCardBody className='cardBody'>
							<h3>{t("viverePraiano.shopping.limoncello.title")}</h3>
							<p>{t("viverePraiano.shopping.limoncello.body")}</p>
						</StyledCardBody>
					</StyledCard>
					<StyledCard>
						<h1>{t("viverePraiano.trekking.title")}</h1>
						<StyledCardBody className='cardBody'>
							<h3>{t("viverePraiano.trekking.dei.title")}</h3>
							<p>{t("viverePraiano.trekking.dei.body")}</p>
						</StyledCardBody>
					</StyledCard>
					<StyledCard>
						<h1>{t("viverePraiano.escursioni.title")}</h1>
						<StyledCardBody className='cardBody'>
							<h3>{t("viverePraiano.escursioni.capri.title")}</h3>
							<p>{t("viverePraiano.escursioni.capri.body")}</p>
						</StyledCardBody>
					</StyledCard>
					<StyledCard>
						<h1>{t("viverePraiano.ristoranti.title")}</h1>
						<StyledCardBody className='cardBody'>
							<h3>{t("viverePraiano.ristoranti.tramonto.title")}</h3>
							<p>{t("viverePraiano.ristoranti.tramonto.body")}</p>
						</StyledCardBody>
					</StyledCard>
					<StyledCard>
						<h1>{t("viverePraiano.nightclub.title")}</h1>
						<StyledCardBody className='cardBody'>
							<h3>{t("viverePraiano.nightclub.rooftop.title")}</h3>
							<p>{t("viverePraiano.nightclub.rooftop.body")}</p>
						</StyledCardBody>
					</StyledCard>
				</StyledCardContainer>
			</div>
		</div>
	);
};

const StyledParallaxImageContainer = styled.div`
  background-attachment: fixed;
  background-image: url("/imgs/vista/thumbnails/20.JPG");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  height: 50vh;
  display: grid;
  align-items: center;
  justify-items: center;
`;

const StyledVistaContainer = styled.div`
  padding: 2rem 1rem;
  color: white;
  min-width: 551px;
  min-height: 155px;
  background-color: var(--color-primary);
  text-align: center;
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
`;

const StyledConchigliaImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const StyledCardContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const StyledCard = styled.div`
  text-align: center;
  width: 80%;
  margin: 0 auto;
  min-height: 102px;

  h1 {
    color: var(--color-primary);
    margin-bottom: 2rem;
  }
`;

const StyledCardBody = styled.div`
  margin-bottom: 3rem;
  border-bottom: 3px solid black;

  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export default ViverePraiano;
