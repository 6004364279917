import React from "react";
import styled from "styled-components";
const CardComponent = ({title, children, image}) => {
	return (
		<StyledCard>
			<StyledCardBody>
				<h2>{title}</h2>
				{children}
			</StyledCardBody>
			<StyledImageContainer>
				<img src={`/imgs/${image}`} alt='Camera'></img>
			</StyledImageContainer>
		</StyledCard>
	);
};

const StyledCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 4rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const StyledCardBody = styled.div`
  width: 30%;
  text-align: right;

  h2 {
    margin-bottom: 1.2rem;
  }

  @media (max-width: 768px) {
    order: 2;
    text-align: center;
    width: 100%;
  }
`;

const StyledImageContainer = styled.div`
  position: relative;
  width: 50%;

  img {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default CardComponent;
