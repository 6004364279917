import React from "react";
import SwiperWrapper from "../components/SwiperWrapper";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const camere = [
	{
		slug: "camera-1",
		nome: "Camera 1",

		foto: [
			{
				original: "/imgs/camere/camera101/original/camera101_1.jpg",
				thumbnail: "/imgs/camere/camera101/thumbnail/camera101_1.jpg",
				width: "100%",
				originalAlt:
					"Foto della Camera101 che mostra il letto spazioso e il luminoso balcone",
				thumbnailAlt:
					"Foto della Camera101 che mostra il letto spazioso e il luminoso balcone",
			},
			{
				original: "/imgs/camere/camera101/original/camera101_2.jpg",
				thumbnail: "/imgs/camere/camera101/thumbnail/camera101_2.jpg",
				width: "100%",
				originalAlt:
					"Foto della Camera101 che mostra il letto spazioso e il lumisono balcone",
				thumbnailAlt:
					"Foto della Camera101 che mostra il letto spazioso e il lumisono balcone",
			},
			{
				original: "/imgs/camere/camera101/original/camera101_5.jpg",
				thumbnail: "/imgs/camere/camera101/thumbnail/camera101_5.jpg",
				width: "100%",
			},
			// {
			// 	original: "/imgs/camere/camera101/original/camera101_7.jpg",
			// 	thumbnail: "/imgs/camere/camera101/thumbnail/camera101_7.jpg",
			// 	width: "100%",
			// },
			// {
			// 	original: "/imgs/camere/camera101/original/camera101_8.jpg",
			// 	thumbnail: "/imgs/camere/camera101/thumbnail/camera101_8.jpg",
			// 	width: "100%",
			// },
			{
				original: "/imgs/camere/camera101/original/camera101_10.jpg",
				thumbnail: "/imgs/camere/camera101/thumbnail/camera101_10.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera101/original/camera101_11.jpg",
				thumbnail: "/imgs/camere/camera101/thumbnail/camera101_11.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera101/original/camera101_12.jpg",
				thumbnail: "/imgs/camere/camera101/thumbnail/camera101_12.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera101/original/camera101_13.jpg",
				thumbnail: "/imgs/camere/camera101/thumbnail/camera101_13.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera101/original/camera101_14.jpg",
				thumbnail: "/imgs/camere/camera101/thumbnail/camera101_14.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera101/original/camera101_vista_1.jpg",
				thumbnail: "/imgs/camere/camera101/thumbnail/camera101_vista_1.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera101/original/camera101_vista_2.jpg",
				thumbnail: "/imgs/camere/camera101/thumbnail/camera101_vista_2.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera101/original/camera101_bagno_1.jpg",
				thumbnail: "/imgs/camere/camera101/thumbnail/camera101_bagno_1.jpg",
				width: "100%",
			},
		],
	},
	{
		slug: "camera-2",
		nome: "Camera 2",
		foto: [
			{
				original: "/imgs/camere/camera102/original/camera102_15.jpg",
				thumbnail: "/imgs/camere/camera102/thumbnail/camera102_15.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera102/original/camera102_16.jpg",
				thumbnail: "/imgs/camere/camera102/thumbnail/camera102_16.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera102/original/camera102_17.jpg",
				thumbnail: "/imgs/camere/camera102/thumbnail/camera102_17.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera102/original/camera102_bagno_1.jpg",
				thumbnail: "/imgs/camere/camera102/thumbnail/camera102_bagno_1.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera102/original/camera102_bagno_2.jpg",
				thumbnail: "/imgs/camere/camera102/thumbnail/camera102_bagno_2.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera102/original/camera102_bagno_3.jpg",
				thumbnail: "/imgs/camere/camera102/thumbnail/camera102_bagno_3.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera102/original/camera102_bagno_4.jpg",
				thumbnail: "/imgs/camere/camera102/thumbnail/camera102_bagno_4.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera102/original/camera102_vista_1.jpg",
				thumbnail: "/imgs/camere/camera102/thumbnail/camera102_vista_1.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera102/original/camera102_vista_2.jpg",
				thumbnail: "/imgs/camere/camera102/thumbnail/camera102_vista_2.jpg",
				width: "100%",
			},
		],
	},
	{
		slug: "camera-3",
		nome: "Camera 3",
		foto: [
			{
				original: "/imgs/camere/camera103/original/camera103_24.jpg",
				thumbnail: "/imgs/camere/camera103/thumbnail/camera103_24.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera103/original/camera103_25.jpg",
				thumbnail: "/imgs/camere/camera103/thumbnail/camera103_25.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera103/original/camera103_26.jpg",
				thumbnail: "/imgs/camere/camera103/thumbnail/camera103_26.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera103/original/camera103_27.jpg",
				thumbnail: "/imgs/camere/camera103/thumbnail/camera103_27.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera103/original/camera103_28.jpg",
				thumbnail: "/imgs/camere/camera103/thumbnail/camera103_28.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera103/original/camera103_29.jpg",
				thumbnail: "/imgs/camere/camera103/thumbnail/camera103_29.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera103/original/camera103_30.jpg",
				thumbnail: "/imgs/camere/camera103/thumbnail/camera103_30.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera103/original/camera103_bagno_1.jpg",
				thumbnail: "/imgs/camere/camera103/thumbnail/camera103_bagno_1.jpg",
				width: "100%",
			},
			{
				original: "/imgs/camere/camera103/original/camera103_bagno_2.jpg",
				thumbnail: "/imgs/camere/camera103/thumbnail/camera103_bagno_2.jpg",
				width: "100%",
			},
		],
	},
];

const Camera = (props) => {
	const {match} = props;
	const {t} = useTranslation();
	const camera = camere.filter((c) => c.slug === match.params.id)[0];
	return (
		<>
			<StyledCameraTitleContainer id="header">
				<h1>{camera.nome}</h1>
			</StyledCameraTitleContainer>
			<StyledLightBoxSection>
				<SwiperWrapper images={camera.foto} />
			</StyledLightBoxSection>
			<div className='container'>
				<StyledCameraInfoContainer className='camera-info'>
					<StyledCameraDescriptionParagraph>
						{t("camera.info")}
						<br />
						{t("camera.contactus")}{" "}
						<StyledMailto href='mailto:info@ilcoralloamalficoast.com'>
							info@ilcoralloamalficoast.com
            </StyledMailto>
					</StyledCameraDescriptionParagraph>
					<StyledCameraServicesContainer>
						<div>
							<img src='/imgs/icons/AC.png' alt={t("camera.servizi.AC")} />
							<p>{t("camera.servizi.AC")}</p>
						</div>
						<div>
							<img src='/imgs/icons/WiFi.png' alt={t("camera.servizi.WIFI")} />
							<p>{t("camera.servizi.WIFI")}</p>
						</div>
						<div>
							<img src='/imgs/icons/TV.png' alt={t("camera.servizi.TV")} />
							<p>{t("camera.servizi.TV")}</p>
						</div>
						<div>
							<img src='/imgs/icons/SAFE.png' alt={t("camera.servizi.SAFE")} />
							<p>{t("camera.servizi.SAFE")}</p>
						</div>
					</StyledCameraServicesContainer>
					<StyledAltriServiziParagraph className='altriServizi'>
						{t("camera.servizi.ALTRI")}
					</StyledAltriServiziParagraph>
				</StyledCameraInfoContainer>
			</div>
		</>
	);
};

const StyledCameraTitleContainer = styled.div`
  padding: 3rem 2rem;
  text-align: center;
  color: white;
  background-color: var(--color-primary);
  margin-top: 3rem;
  font-size: 1.5rem;
`;

const StyledCameraInfoContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr minmax(50px, 1fr) 1fr;
  grid-template-rows: 1fr 1fr;

  @media (max-width: 769px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;
  }
`;

const StyledLightBoxSection = styled.section`
  background-color: var(--color-secondary);
`;

const StyledCameraDescriptionParagraph = styled.p`
  grid-column: 1/2;
  grid-row: 1/3;
  @media (max-width: 769px) {
    grid-row: 1/2;
    grid-column: unset;
    text-align: center;
  }
`;

const StyledCameraServicesContainer = styled.div`
  grid-column: 3/4;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  div {
    text-align: center;
    color: rgba(109, 109, 109, 0.842);
    font-size: 0.75rem;
  }

  @media (max-width: 768px) {
    grid-row: 2/3;
    grid-column: unset;
    grid-template-columns: repeat(2, 1fr);

    div {
      margin: 0.4rem;
    }
  }
`;

const StyledAltriServiziParagraph = styled.p`
  margin-top: 0.75rem;
  grid-column: 3/4;
  grid-row: 2/3;
  text-align: center;
  color: rgba(109, 109, 109, 0.842);
  font-size: 0.75rem;

  @media (max-width: 768px) {
    grid-row: 3/4;
    grid-column: unset;
  }
`;

const StyledMailto = styled.a`
  color: var(--color-primary);
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default Camera;
