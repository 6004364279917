import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
const Besaferate = () => {
	const { t } = useTranslation();
	return (
		<StyledSection>
			<StyledDivOne>
				<div className="introduction">
					<img
						alt="besaferate logo"
						src="https://www.besaferate.com//wp-content/uploads/2019/01/logo-besaferate-colore-2.png"
					/>
					<div className="rectangle"></div>
					<h1>{t("besaferate.title1")}</h1>
					<p>{t("besaferate.caption1")}</p>
				</div>
				<div className="content">
					<h2>{t("besaferate.title2")}</h2>
					<p>{t("besaferate.caption2")}</p>
				</div>
				<div className="gridLayout">
					<div>
						<h3>{t("besaferate.features.rimborso.titolo")}</h3>
						<p>{t("besaferate.features.rimborso.contenuto")}</p>
					</div>
					<div>
						<h3>{t("besaferate.features.garanzia.titolo")}</h3>
						<p>{t("besaferate.features.garanzia.contenuto")}</p>
					</div>
					<div>
						<h3>{t("besaferate.features.assistenza.titolo")}</h3>
						<p>{t("besaferate.features.assistenza.contenuto")}</p>
					</div>
					<div>
						<h3>{t("besaferate.features.anticipo.titolo")}</h3>
						<p>{t("besaferate.features.anticipo.contenuto")}</p>
					</div>
					<div>
						<h3>{t("besaferate.features.assistenzalegale.titolo")}</h3>
						<p>{t("besaferate.features.assistenzalegale.contenuto")}</p>
					</div>
					<div>
						<h3>{t("besaferate.features.rimborso2.titolo")}</h3>
						<p>{t("besaferate.features.rimborso2.contenuto")}</p>
					</div>
					<div>
						<h3>{t("besaferate.features.assistenza2.titolo")}</h3>
						<p>{t("besaferate.features.assistenza2.contenuto")}</p>
					</div>
					<div>
						<h3>{t("besaferate.features.copertura.titolo")}</h3>
						<p>{t("besaferate.features.copertura.contenuto")}</p>
					</div>
				</div>
				<p className="polizza">
					{t("besaferate.polizza-text")}{" "}
					<a href="https://besafesuite.com/insurance/besaferate-es/">
						{t("besaferate.polizza-link")}
					</a>
				</p>
			</StyledDivOne>
		</StyledSection>
	);
};

const StyledSection = styled.section`
	min-height: 100vh;
	padding: 1em;
	/* background-color: var(--color-gold);
	color: white; */
	display: grid;
	justify-content: center;
`;

const StyledDivOne = styled.div`
	padding-top: 5em;
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-rows: 1fr 1fr;
	row-gap: 2em;
	column-gap: 1.5em;

	@media (max-width: 850px) {
		& {
			grid-template-columns: 1fr;
		}
	}

	h1 {
		font-size: 2.25em;
	}

	h2 {
		font-size: 2.1em;
	}

	div {
		align-self: center;
		p {
			margin-top: 1.25em;
			font-size: 1.1em;
		}
	}

	.introduction {
		position: relative;

		img {
			width: 40%;
			z-index: 2;
		}
	}

	.content {
		grid-row: 2/3;
	}

	.gridLayout {
		align-self: unset;
		grid-column: 2/3;
		grid-row: 1/-1;
		display: grid;
		align-items: center;
		grid-template-columns: 1fr 1fr;
		text-align: center;
		column-gap: 2em;
		row-gap: 2em;
		color: white;
		h3 {
			font-size: 1.5em;
			text-transform: capitalize;
			/* color: var(--color-secondary); */
		}

		p {
			font-size: 1em;
		}

		div {
			padding: 1.3em;
			height: 100%;
			background-color: var(--color-primary);
		}

		@media (max-width: 850px) {
			grid-column: 1/1;
			grid-row: auto;
			grid-template-columns: 1fr;
		}
	}

	.polizza {
		grid-column: 1/-1;
		text-align: center;
	}
`;

export default Besaferate;
