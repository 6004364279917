import React from "react";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";

const Map = ({ location }) => {
  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyBEn-s8Fj4-AB9wQRwq6Xf2P_SknxdNGWI",
        }}
        defaultCenter={location}
        defaultZoom={17}
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </>
  );
};

const LocationPin = ({ text }) => (
  <StyledMarker>
    <StyledIcon icon={locationIcon} />
    <StyledMarkerText>{text}</StyledMarkerText>
  </StyledMarker>
);

const StyledMarker = styled.div`
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--color-primary);
`;

const StyledIcon = styled(Icon)`
  font-size: 7rem;
`;

const StyledMarkerText = styled.p`
  font-size: 1rem;
`;

export default Map;
