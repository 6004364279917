import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {useTranslation} from 'react-i18next';


const Dropdown = () => {
	const [open, setOpen] = useState(false);
	const toggle = () => setOpen(!open);
	const history = useHistory();

	const {t} = useTranslation();
	return (
		<StyledDropdown onClick={toggle}>
			<StyledClickable>{t('navbar.camere.dropdown')}</StyledClickable>
			{open ? (
				<StyledDropdownItems>
					<div>
						<button
							onClick={() => {
								history.push(`/camera/${"camera-1"}#header`);
							}}
						>
							{t('navbar.camere.camera101')}
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								history.push(`/camera/${"camera-2"}#header`);
							}}
						>
							{t('navbar.camere.camera102')}
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								history.push(`/camera/${"camera-3"}#header`);
							}}
						>
							{t('navbar.camere.camera103')}
						</button>
					</div>
				</StyledDropdownItems>
			) : (
				<></>
			)}
		</StyledDropdown>
	);
};

const StyledDropdown = styled.div`
  position: relative;
`;

const StyledClickable = styled.p`
  cursor: pointer;
`;

const StyledDropdownItems = styled.div`
  position: absolute;
  min-width: 150px;
  top: 2rem;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  flex-direction: column;
  background-color: var(--color-gold);
  padding: 1rem 1rem;

  button {
    border: none;
    padding: none;
    background-color: transparent;
    color: black;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default Dropdown;
