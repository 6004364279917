import React from "react";
import styled from "styled-components";

const Toggle = ({ click }) => {
  return (
    <StyledButton onClick={click}>
      <StyledButtonLine />
      <StyledButtonLine />
      <StyledButtonLine />
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 35px;
  width: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  @media (min-width: 869px) {
    display: none;
  }
`;

const StyledButtonLine = styled.div`
  width: 30px;
  height: 1px;
  border: 3px solid var(--color-primary);
  background-color: var(--color-primary);
`;

export default Toggle;
