import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import Toggle from "./Toggle";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Navbar = ({ drawClickHandler }) => {
	const { t, i18n } = useTranslation();

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
	};

	return (
		<StyledNavbar>
			<div>
				<Toggle click={drawClickHandler} />
				<h3>Il Corallo</h3>
			</div>
			<StyledLinkItems>
				<StyledUl>
					<li>
						<Link to="/#header">{t("navbar.home")}</Link>
					</li>
					<li>
						<Dropdown />
					</li>
					<li>
						<Link to="/viverePraiano#header">{t("navbar.viverePraiano")}</Link>
					</li>
					<li>
						<Link to="/informazioni#header">{t("navbar.informazioni")}</Link>
					</li>
					<li>
						<Link to="/besaferate#header">BeSafe Rate</Link>
					</li>
					<li>
						<StyledFlagButtons
							className="flagBtn"
							onClick={() => changeLanguage("it")}>
							<img src="/imgs/it.svg" alt={t("navbar.altUk")} />
						</StyledFlagButtons>
					</li>
					<li>
						<StyledFlagButtons
							className="flagBtn"
							onClick={() => changeLanguage("en")}>
							<img src="/imgs/uk.svg" alt={t("navbar.altIt")} />
						</StyledFlagButtons>
					</li>
				</StyledUl>
				<StyledCatFilled href="https://booking.ericsoft.com/BookingEngine/Book?idh=FB9C455E247A8019">
					{t("button.book")}
				</StyledCatFilled>
			</StyledLinkItems>
		</StyledNavbar>
	);
};

const StyledNavbar = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	background-color: var(--color-gold);
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 100;

	h3 {
		font-family: "Playfair Display", serif;
		font-size: 24px;
	}

	div {
		display: flex;
		align-items: center;
		gap: 28px;
	}
`;

const StyledUl = styled.ul`
	display: flex;
	justify-content: space-around;
	list-style: none;
	gap: 28px;
	@media (max-width: 868px) {
		display: none;
	}
`;

const StyledCatFilled = styled.a`
	padding: 0.5rem 1.5rem;
	background-color: var(--color-primary);
	color: white;
`;

const StyledLinkItems = styled.div`
	@media (max-width: 868px) {
		display: none;
	}
`;

const StyledFlagButtons = styled.button`
	border: none;
	background: none;
	cursor: pointer;
	img {
		width: 1.65rem;
		&:hover {
			box-shadow: 2px 2px 3px black;
		}
	}
`;

export default Navbar;
