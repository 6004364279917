import React from "react";
import styled from "styled-components";
import { SRLWrapper } from "simple-react-lightbox";
import { useTranslation } from "react-i18next";

const GalleryComponent = () => {
  const { t } = useTranslation();

  return (
    <StyledGallery>
      <StyledTitle> Gallery </StyledTitle>
      <SRLWrapper>
        <StyledPhotoContainer>
          <a href='/imgs/vista/quality/4.jpg'>
            <img src='/imgs/vista/thumbnails/4.jpg' alt={t("galleryAlt")} />
          </a>
          <a href='/imgs/vista/quality/6.jpg'>
            <img src='/imgs/vista/thumbnails/6.jpg' alt={t("galleryAlt")} />
          </a>
          <a href='/imgs/vista/quality/18.jpg'>
            <img src='/imgs/vista/thumbnails/18.jpg' alt={t("galleryAlt")} />
          </a>
          <a href='/imgs/vista/quality/19.jpg'>
            <img src='/imgs/vista/thumbnails/19.jpg' alt={t("galleryAlt")} />
          </a>
        </StyledPhotoContainer>
      </SRLWrapper>
    </StyledGallery>
  );
};

const StyledGallery = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const StyledTitle = styled.h2`
  color: var(--color-primary);
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
`;

const StyledPhotoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  div {
    width: 100%;
  }

  img {
    width: 100%;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export default GalleryComponent;
