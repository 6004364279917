import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NotFound = () => {
  return (
    <StyledSection>
      <StyledCentralDiv>
        <h1>Pagina non trovata</h1>
        <StyledFlexContainer>
          <StyledCatFillLink to='/home' className='cat-fill'>
            Torna alla Home
          </StyledCatFillLink>
          <StyledCatBorderA href='#' className='cat-border'>
            Prenota Ora
          </StyledCatBorderA>
        </StyledFlexContainer>
      </StyledCentralDiv>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  background-color: var(--color-secondary);
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const StyledCentralDiv = styled.div`
  background-color: white;
  color: black;
  padding: 2rem;
  box-shadow: 10px 10px 10px black;
  text-align: center;
  max-width: calc(100vw - 300px);
  width: 500px;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    max-width: calc(100vw - 15px);
  }
`;

const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledCatFillLink = styled(Link)`
  display: block;
  padding: 0.5rem 1.5rem;
  z-index: 1;
  cursor: pointer;
  background-color: var(--color-primary);
  outline: 1px solid var(--color-primary);
  color: white;
  text-decoration: none;
`;

const StyledCatBorderA = styled.a`
  display: block;
  padding: 0.5rem 1.5rem;
  z-index: 1;
  cursor: pointer;
  outline: 3px solid var(--color-primary);
  color: var(--color-primary);
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--color-primary);
    color: white;
  }
`;

export default NotFound;
