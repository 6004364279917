import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import CardComponent from "../components/CardComponent";
import GalleryComponent from "../components/GalleryComponent";
import {useTranslation} from "react-i18next";
const Home = () => {
	const {t} = useTranslation();
	console.log(t("button.more"));
	return (
		<>
			<StyledJumbotron id="header">
				<StyledJumbotronContainer>
					<StyledTitle>{t("home.title")}</StyledTitle>
					<StyledButtonContainer>
						<StyledCatFill href="https://booking.ericsoft.com/BookingEngine/Book?idh=FB9C455E247A8019">{t("button.book")}</StyledCatFill>
						<StyledCatBorder href='#camere'>{t("button.more")}</StyledCatBorder>
					</StyledButtonContainer>
					<StyledCoralloImg
						src='/imgs/corallo.svg'
						width={148.3}
						height={192.29}
						alt='Corallo Illustrazione'
					></StyledCoralloImg>
				</StyledJumbotronContainer>
			</StyledJumbotron>
			<StyledInfoSection>
				<div className='container'>
					<CardComponent
						title={t("home.info1.title")}
						image='camere/camera101/thumbnail/camera101_14.jpg'
					>
						<p>{t("home.info1.body")}</p>
					</CardComponent>
					<CardComponent
						title={t("home.info2.title")}
						image='camere/camera101/thumbnail/camera101_11.jpg'
					>
						<p>{t("home.info2.body")}</p>
					</CardComponent>
				</div>
			</StyledInfoSection>
			<StyledCamereSection id='camere'>
				<div className='container'>
					<h1>{t("home.camere.title")}</h1>
					<p>{t("home.camere.body")}</p>
				</div>

				<StyledCamereContainer>
					<StyledCameraCard>
						<Link to='/camera/camera-101#header'>
							<img
								src='/imgs/camere/camera101/thumbnail/camera101_1.jpg'
								alt="Camera 1's bed"
							/>
						</Link>
						<h2>Camera 1</h2>
						<StyledCatLinkFill to='/camera/camera-101#header'>
							{t("button.more")}
						</StyledCatLinkFill>
					</StyledCameraCard>
					<StyledCameraCard>
						<Link to='/camera/camera-102#header'>
							<img
								src='/imgs/camere/camera102/thumbnail/camera102_15.jpg'
								alt="Camera 2's bed"
							/>
						</Link>
						<h2>Camera 2</h2>
						<StyledCatLinkFill to='/camera/camera-102#header'>
							{t("button.more")}
						</StyledCatLinkFill>
					</StyledCameraCard>
					<StyledCameraCard>
						<Link to='/camera/camera-103#header'>
							<img
								src='/imgs/camere/camera103/thumbnail/camera103_25.jpg'
								alt="Camera 3's bed"
							/>
						</Link>
						<h2>Camera 3</h2>
						<StyledCatLinkFill to='/camera/camera-103#header'>
							{t("button.more")}
						</StyledCatLinkFill>
					</StyledCameraCard>
				</StyledCamereContainer>
			</StyledCamereSection>
			<section>
				<StyledParallaxImageContainer>
					<StyledVistaContainer>
						<StyledTitle>{t('home.vista.title')}</StyledTitle>
						<StyledConchigliaImg
							src='/imgs/corallo.svg'
							alt='corallo illustration'
						></StyledConchigliaImg>
					</StyledVistaContainer>
				</StyledParallaxImageContainer>
				<div className='container'>
					<p> </p>
					<GalleryComponent t={t} />
				</div>
			</section>
		</>
	);
};

const StyledJumbotron = styled.section`
  height: 100vh;
  background-image: url("/imgs/camere/camera101/thumbnail/camera101_12.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;

  display: grid;
  align-items: center;
  justify-items: center;
`;

const StyledJumbotronContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-secondary);
  color: white;
  padding: 2rem 1rem;
  min-width: 500px;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    min-width: 75%;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-top: 2rem;
  @media (max-width: 768px) {
				flex-direction: column;
				gap: 20px;
  }
`;

const StyledInfoSection = styled.section`
  background-color: var(--color-secondary);
  color: white;
  padding: 2rem 0;
`;

const StyledCamereSection = styled.section`
  background-color: var(--color-gold);
  text-align: center;
  padding-top: 1rem;
`;

const StyledCatFill = styled.a`
  display: block;
  padding: 0.5rem 1.5rem;
  font-size: 22px;
  z-index: 1;
  cursor: pointer;
  background-color: var(--color-primary);
  outline: 1px solid var(--color-primary);
  transition: all 0.3s ease;
  color: white;
  text-decoration: none;
  &:hover {
    transform: scale(1.1);
  }
`;

const StyledCatLinkFill = styled(Link)`
  display: block;
  padding: 0.5rem 1.5rem;
  font-size: 22px;
  z-index: 1;
  cursor: pointer;
  background-color: var(--color-primary);
  outline: 1px solid var(--color-primary);
  transition: all 0.3s ease;
  color: white;
  margin: 0 auto;
  margin-top: 1rem;
  text-decoration: none;
  width: 75%;
`;

const StyledCatBorder = styled.a`
  display: block;
  padding: 0.5rem 1.5rem;
  font-size: 22px;
  z-index: 1;
  cursor: pointer;
  outline: 3px solid var(--color-primary);
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--color-primary);
  }
`;

const StyledCoralloImg = styled.img`
  position: absolute;
  content: "";
  top: 0;
  left: 0;
		z-index: 0;

		@media (max-width: 768px) {
				display: none;
		}
`;

const StyledParallaxImageContainer = styled.div`
  background-attachment: fixed;
  background-image: url("/imgs/camere/camera102/thumbnail/camera102_vista_1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 35vh;
  display: grid;
  align-items: center;
  justify-items: center;
`;

const StyledVistaContainer = styled.div`
  padding: 2rem 1rem;
  color: white;
  min-width: 551px;
  min-height: 155px;
  background-color: var(--color-primary);
  text-align: center;
  position: relative;
  display: grid;
  align-items: center;
		justify-items: center; 

		@media (max-width: 768px) {
				min-width: 75%;

		}
`;

const StyledConchigliaImg = styled.img`
  position: absolute;
  width: 100px;
  /* transform: rotate(15deg); */
  bottom: 10px;
  left: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledTitle = styled.h1`
  font-size: 4rem;
  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const StyledCamereContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 3rem 0.3rem;
  gap: 12px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const StyledCameraCard = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-bottom: 1.4rem;
  padding-top: 1.4rem;
  background-color: var(--color-secondary);

  h2 {
    color: white;
  }

  img {
    width: 90%;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  img:hover {
    transform: scale(1.03);
  }
`;

export default Home;
