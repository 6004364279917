import React from "react";
import styled from "styled-components";
import Map from "../components/Map";
import {useTranslation} from "react-i18next";

const Informazioni = () => {
	const {t} = useTranslation();

	const location = {
		address: "Via Gennario Capriglione, 123, 84010 Praiano SA",
		lat: 40.61324812422141,
		lng: 14.522257454092808,
	};

	return (
		<StyledInfoSection id="header">
			<StyledGridContainer>
				<StyledCardInfo>
					<h1>{t("informazioni.title")}</h1>
					<p>{t("informazioni.checkin")}</p>
					<p>{t("informazioni.colazione")}</p>
					<p>{t("informazioni.parcheggio")}</p>
					<StyledGridDivieti>
						<StyledDivieto>
							<img src='/imgs/icons/no-Pet.png' alt='no-pet icon' />
							<p>{t("informazioni.pet")}</p>
						</StyledDivieto>
						<StyledDivieto>
							<img src='/imgs/icons/no-smoke.png' alt='no-smoke icon' />
							<p>{t("informazioni.smoke")}</p>
						</StyledDivieto>
            <StyledDivieto>
							<img src='/imgs/icons/only-double.svg' alt='only-double icon' />
							<p>{t("informazioni.doppie")}</p>
						</StyledDivieto>
					</StyledGridDivieti>
				</StyledCardInfo>
				<StyledGridMapInfo>
					<Map location={location} />
				</StyledGridMapInfo>
			</StyledGridContainer>
		</StyledInfoSection>
	);
};

const StyledInfoSection = styled.section`
  background-color: var(--color-secondary);
  height: 100vh;
  @media (max-width: 500px) {
    height: auto;
    padding-top: 4rem;
  }
`;

const StyledGridContainer = styled.div`
  width: 75%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: 1186px) {
    grid-template-columns: 1fr;
    width: 100%;
    margin: 0;
  }
`;
const StyledGridMapInfo = styled.div`
  background-color: white;
  color: black;
  padding: 4rem;
  height: calc(100vw / 2 - 15px);
  width: calc(100vw / 2 - 15px);
  max-height: 590px;
  max-width: 590px;
  padding: 0rem;
  margin: 0 auto;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    width: 100%;
    margin: 0;
  }
`;

const StyledCardInfo = styled.div`
  background-color: white;
  color: black;
  padding: 2rem;
  height: calc(100vw / 2 - 15px);
  width: calc(100vw / 2 - 15px);
  max-height: 590px;
  max-width: 590px;
  margin: 0 auto;

  h1 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
  @media (max-width: 1186px) {
    width: calc(100vw - 15px);
    min-height: 100vh;
    margin: 0 auto;
  }
`;

const StyledGridDivieti = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px;
`;

const StyledDivieto = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    margin-bottom: 0;
  }
`;

export default Informazioni;
