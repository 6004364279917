import React, { useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import SideDrawer from "./components/SideDrawer";

import Home from "./pages/Home";

import "./App.css";
import Navbar from "./components/Navbar";
import ViverePraiano from "./pages/ViverePraiano";
import Camera from "./pages/Camera";
import Footer from "./components/Footer";
import Informazioni from "./pages/Informazioni";
import NotFound from "./pages/NotFound";
import Besaferate from "./pages/Besaferate";

function App() {
	const [sideDrawOpen, setSideDrawOpen] = useState(false);

	const drawerToggleClickHandler = () => {
		setSideDrawOpen(!sideDrawOpen);
	};
	return (
		<SimpleReactLightbox>
			<BrowserRouter>
				<Navbar drawClickHandler={drawerToggleClickHandler} />
				<SideDrawer
					show={sideDrawOpen}
					drawClickHandler={drawerToggleClickHandler}
				/>
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route path="/home">
						<Home />
					</Route>
					<Route
						path="/camera/:id"
						render={(props) => {
							return <Camera {...props} />;
						}}
					/>
					<Route path="/viverePraiano">
						<ViverePraiano />
					</Route>
					<Route path="/informazioni">
						<Informazioni />
					</Route>
					<Route path="/besaferate">
						<Besaferate />
					</Route>
					<Route>
						<NotFound />
					</Route>
				</Switch>
				<Footer />
			</BrowserRouter>
		</SimpleReactLightbox>
	);
}

export default App;
