import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Map from "./Map";

const Footer = () => {
	const {t} = useTranslation();

	const location = {
		address: "Via Gennaro Capriglione, 123, 84010 Praiano SA",
		lat: 40.61324812422141,
		lng: 14.522257454092808,
	};

	return (
		<StyledSection>
			<div className='container'>
				<StyledDiv>
					<div>
						<h2>{t("footer.info.title")}</h2>
						<p>
							{t("footer.info.indirizzo")}:<br />
							<b>Via Gennaro Capriglione, 123 Praiano (SA)</b>
						</p>
						<p>
							{t("footer.info.email")}:<br />
							<a href="mailto:info@ilcoralloamalficoast.com">info@ilcoralloamalficoast.com</a>
						</p>
						<p>
							{t("footer.info.telefono")}:<br />
							<a href="tel:00393396029485">+39 339 6029 485</a><br />
							<a href="tel:0039089813069">+39 089 813 069</a>
						</p>
					</div>
					<div>
						<h2>{t("footer.altre.title")}</h2>
						<p>
							<a href='https://www.stellamarinaamalficoast.it'>Stella Marina</a>
						</p>
					</div>

					<StyledMap>
						<Map location={location} />
					</StyledMap>
				</StyledDiv>
			</div>
			<StyledCopyright>
				<p>Copyright 2020 | P.IVA 05355710657</p>
				<p>Made with ❤ by <a href="https://stayerk.me">Andrea Ercolino</a></p>
			</StyledCopyright>
		</StyledSection>
	);
};

const StyledSection = styled.section`
  background-color: var(--color-gold);
  min-height: 33vh;
  padding: 3rem 0;
  position: relative;
`;

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  min-height: 300px;
  font-size: 0.95rem;
  gap: 20px;
  h2 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0.5rem;
  }

  a {
    color: black;
    font-weight: bold;
    text-decoration: underline;
  }
  @media (max-width: 850px) {
    grid-template-columns: 1fr;
    gap: 20px;
    text-align: center;
  }
`;

const StyledCopyright = styled.div`
  width: 100%;
  background-color: var(--color-secondary);
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 0.5rem 1rem;

		a {
				color: white;
				font-weight: bold;
				text-decoration: none;
				cursor: pointer;

				&:hover{
						text-decoration: underline;
				}
		}

  @media (max-width: 850px) {
    flex-direction: column;
    text-align: center;
  }
`;

const StyledMap = styled.div`
  @media (max-width: 850px) {
    height: 300px;
    margin-bottom: 4rem;
  }
`;

export default Footer;
